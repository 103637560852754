import { cn } from "../../../../core/utils/cn";
import ErrorMessage from "./ErrorMessage";
import Hints from "./Hints";
import Label from "./Label";

const FormFieldWrapperCompound = ({ children, className, ...restProps }) => {
  return (
    <div className={cn("bg-transparent", className)} {...restProps}>
      {children}
    </div>
  );
};

FormFieldWrapperCompound.Label = Label;
FormFieldWrapperCompound.Hints = Hints;
FormFieldWrapperCompound.ErrorMessage = ErrorMessage;

export default FormFieldWrapperCompound;
