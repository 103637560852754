import { apiSlice } from ".";
import { generateQueryString } from "../../core/utils/generateQueryString";
import { setTaskDetails } from "../slices/taskSlice";
import { setDismissedItems, setWhatsNewItems } from "../slices/whatsNewSlice";

export const taskApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    whatsNew: builder.query({
      query: () => ({
        url: "/task/v1/whats-new/",
        headers: {
          Accept: "*/*",
        },
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled;
          let checkLength = response?.data?.results?.length > 0;
          // console.log("whatsnew:", checkLength);
          if (checkLength) {
            dispatch(setWhatsNewItems(response?.data?.results));
          }
          if (!checkLength) {
            dispatch(setWhatsNewItems([]));
            dispatch(setDismissedItems([]));
          }
        } catch {}
      },
    }),

    clientPromptList: builder.query({
      query: () => ({
        url: "/task/v1/client-generator-prompt-list/?page_size=100",
        headers: {
          Accept: "*/*",
        },
      }),
    }),

    publishTaskFromList: builder.mutation({
      query: (data) => ({
        url: "/task/v1/publish-task-from-list/",
        headers: {
          Accept: "*/*",
        },
        method: "POST",
        body: data,
      }),
    }),

    createShortFeature: builder.mutation({
      query: (data) => ({
        url: "/task/v1/admin/feature-task-create/",
        method: "POST",
        body: data,
        headers: {
          Accept: "*/*",
        },
      }),
      invalidatesTags: ["medium-site-add"],
    }),

    createFeatureTask: builder.mutation({
      query: (data) => ({
        url: "/task/v1/feature-task/",
        method: "POST",
        body: data,
        headers: {
          Accept: "*/*",
        },
      }),
      // invalidatesTags: ["ticket"],
    }),

    getFeatureList: builder.query({
      query: (args = {}) => {
        const { queryString } = generateQueryString({ queryObject: args });

        return {
          url: `/task/v1/feature-list/${queryString}`,
          headers: {
            Accept: "*/*",
          },
        };
      },
    }),

    getFeatureById: builder.query({
      query: (id) => {
        return {
          url: `/task/v1/feature-list/${id}`,
          headers: {
            Accept: "*/*",
          },
        };
      },
    }),

    getSmallAIFeatures: builder.query({
      query: (args = {}) => {
        const { queryString } = generateQueryString({ queryObject: args });
        return {
          url: `/task/v1/admin/feature-task-create/${queryString}`,
          headers: {
            Accept: "*/*",
          },
        };
      },
      providesTags: ["getSmallAIFeatures"],
    }),

    getSmallAIFeatureById: builder.query({
      query: (args = {}) => {
        const { queryString } = generateQueryString({ queryObject: args });
        return {
          url: `/task/v1/admin/feature-task-create/${queryString}`,
          headers: {
            Accept: "*/*",
          },
        };
      },
      providesTags: ["getSmallAIFeatureById"],
    }),

    updateSmallAIFeature: builder.mutation({
      query: (data) => ({
        url: "/task/v1/admin/feature-task-create/",
        method: "PATCH",
        body: data,
        headers: {
          Accept: "*/*",
        },
      }),
      invalidatesTags: ["getSmallAIFeatures", "getSmallAIFeatureById"],
    }),
    getTaskUserDetails: builder.query({
      query: () => {
        return {
          url: "/task/v1/task-user-details/",
          headers: {
            Accept: "*/*",
          },
        };
      },
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          const response = await queryFulfilled;
          const getTaskUserDetailsResponse = response?.data;
          dispatch(setTaskDetails(getTaskUserDetailsResponse));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getCreditHistory: builder.query({
      query: (args) => {
        const { queryString } = generateQueryString({
          queryObject: args,
        });
        return {
          url: `/task/v1/credit-history${queryString}`,
        };
      },
    }),
    getTaskList: builder.query({
      query: (args) => {
        const { queryString } = generateQueryString({
          queryObject: args,
        });
        return {
          url: `/task/v1/task-list${queryString}`,
          headers: {
            Accept: "*/*",
          },
        };
      },
    }),

    // Our USP
    // Short Info Article (450-2940 Words)

    generateShortInfoArticle: builder.mutation({
      query: (data) => ({
        url: "/task/v1/short-info-article/",
        method: "POST",
        body: data,
        headers: {
          Accept: "*/*",
        },
      }),
      invalidatesTags: ["user-info"],
    }),
    testTimeout: builder.mutation({
      query: (data) => ({
        url: "/seo-analysis/v1/test-timeout/",
        method: "POST",
        body: data,
        headers: {
          Accept: "*/*",
        },
      }),
    }),
  }),
});

export const {
  useWhatsNewQuery,
  useClientPromptListQuery,
  usePublishTaskFromListMutation,
  useCreateShortFeatureMutation,
  useGetFeatureListQuery,
  useGetFeatureByIdQuery,
  useCreateFeatureTaskMutation,
  //
  useGetSmallAIFeaturesQuery,
  useGetSmallAIFeatureByIdQuery,
  useUpdateSmallAIFeatureMutation,
  useUpdateFeatureTaskMutation,

  //
  useGetTaskUserDetailsQuery,

  //
  useGetCreditHistoryQuery,

  //
  useGetTaskListQuery,

  //
  useGenerateShortInfoArticleMutation,
  useTestTimeoutMutation,
} = taskApi;
