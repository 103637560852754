import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { IoMdClose } from "react-icons/io";
import { useModal } from "../../core/ModalContext";
import { cn } from "../../../core/utils/cn";

const Title = ({ title, className }) => {
  const { closeModal } = useModal();

  return (
    <div
      className={cn(
        "sticky left-0 top-0 z-10 flex items-center justify-between gap-3 bg-white p-2 shadow",
        className,
      )}
    >
      <h2 className="text-wrap break-words text-left text-2xl font-bold">
        {title}
      </h2>

      <Tooltip title="Close Modal">
        <IconButton
          onClick={closeModal}
          className="!rounded-lg"
          type="button"
          color="warning"
        >
          <IoMdClose className="h-7 w-7" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Title;
