import { apiSlice } from ".";
import { generateQueryString } from "../../core/utils/generateQueryString";

export const actionTypeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllActionType: builder.query({
      query: () => {
        const { queryString } = generateQueryString({
          queryObject: {
            // page_size: 15, page: 1
          },
        });
        return {
          url: `/seo-analysis/v1/action-type/${queryString}`,
        };
      },
      providesTags: ["getAllActionType"],
    }),

    getActionTypeById: builder.query({
      query: (id) => {
        return {
          url: `/seo-analysis/v1/action-type/${id}`,
        };
      },
      providesTags: ["getActionTypeById"],
    }),

    createActionType: builder.mutation({
      query: (data) => {
        return {
          url: "/seo-analysis/v1/action-type/",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["getAllActionType", "getActionTypeById"],
    }),

    updateActionTypeById: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/seo-analysis/v1/action-type/${id}/`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["getAllActionType", "getActionTypeById"],
    }),

    deleteActionTypeById: builder.mutation({
      query: (id) => {
        return {
          url: `/seo-analysis/v1/action-type/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["getAllActionType", "getActionTypeById"],
    }),
  }),
});

export const {
  useGetAllActionTypeQuery,
  useGetActionTypeByIdQuery,
  useCreateActionTypeMutation,
  useUpdateActionTypeByIdMutation,
  useDeleteActionTypeByIdMutation,
} = actionTypeApiSlice;
