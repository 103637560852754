import { useMemo } from "react";
import { useGetAllActionTypeQuery } from "../../../redux/api/actionTypeApi";
import { actionTypeOptions } from "./utils";

const useGetActionTypeOptions = () => {
  const { data: getAllActionTypeRes, ...getAllActionTypeApiState } =
    useGetAllActionTypeQuery(undefined, {
      skip: true,
    });
  const isLoading =
    getAllActionTypeApiState?.isLoading || getAllActionTypeApiState?.isFetching;

  const options = useMemo(() => {
    return {
      chooseActionTypeOptions: [
        ...actionTypeOptions,
        // ...(getAllActionTypeRes?.map((option) => ({
        //   label: option?.type,
        //   value: option?.id,
        // })) ?? []),
      ],
      isChooseActionTypeOptionsLoading: isLoading,
    };
  }, [getAllActionTypeRes, isLoading]);

  return options;
};

export default useGetActionTypeOptions;
