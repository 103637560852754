import { nanoid } from "@reduxjs/toolkit";
import { forwardRef, useCallback, useMemo } from "react";
import { cn } from "../../core/utils/cn";
import FormFieldWrapper from "./common-components/FormFieldWrapper/index";

const CustomInput = forwardRef(
  (
    {
      required = false,
      type,
      name,
      placeholder,
      defaultValue,
      register,
      className = "",
      classNames = {
        labelClassName: "",
        errorMessageClassName: "",
        wrapperClassName: "",
      },
      disabled = false,
      readOnly,
      label,
      errorMessage,
      hintsMessage,
      ...restProps
    },
    ref,
  ) => {
    const htmlForId = useMemo(() => {
      return name ? name?.trim() : nanoid();
    }, [name]);

    const registerProps = register?.(name);
    const finalRef = ref ? ref : registerProps?.ref;

    const preventMouseWheelNumberChange = useCallback((e, type) => {
      if (type === "number") {
        e?.currentTarget?.blur();
      }
    }, []);

    const inputComponent = (
      <input
        id={htmlForId}
        disabled={disabled}
        required={required}
        type={type}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={cn(
          `flex min-h-14 w-full flex-row justify-between rounded border border-gray-300 bg-transparent px-5 text-sm hover:border-brand-main-400 focus:border-brand-main-400 disabled:bg-gray-100 disabled:border-gray-300 disabled:text-[#979798] read-only:!border-gray-300`,
          {
            "!my-0": !!label || !!errorMessage,
          },
          className,
        )}
        style={{ outline: "none" }}
        {...registerProps}
        ref={finalRef}
        {...restProps}
        onWheel={(e) => {
          restProps?.onWheel?.(e);
          registerProps?.onWheel?.(e);
          preventMouseWheelNumberChange(e, type);
        }}
        readOnly={readOnly}
      />
    );

    let content = inputComponent;
    if (!!errorMessage || !!label || !!hintsMessage) {
      content = (
        <FormFieldWrapper
          label={label}
          errorMessage={errorMessage}
          hintsMessage={hintsMessage}
          htmlFor={htmlForId}
          className={classNames?.wrapperClassName}
          classNames={{
            labelClassName: classNames?.labelClassName,
            errorMessageClassName: classNames?.errorMessageClassName,
          }}
        >
          {inputComponent}
        </FormFieldWrapper>
      );
    }

    return content;
  },
);

export default CustomInput;
