import * as Yup from "yup";

export const manageMarketplaceFormSchema = Yup.object().shape({
  marketplaces: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required("Title is required"),
      }),
    )
    .test(
      "unique-titles",
      "Every form field's title must be unique",
      (array) => {
        const titles = array?.map((obj) => obj?.title) ?? [];
        const uniqueTitles = new Set(titles);
        return titles.length === uniqueTitles.size;
      },
    )
    .min(1, "At least one marketplace option is required")
    .required("At least one marketplace option is required"),
});
