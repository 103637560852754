import * as Yup from "yup";

export const affiliateProgramFormSchema = Yup.object().shape({
  affiliate_marketplace: Yup.string().required("Marketplace is required"),
  // logo: singleFileValidate({
  //   allowList: ["png", "jpg", "jpeg", "webp"],
  // }),
  product_name: Yup.string().required("Product name is required"),
  niche: Yup.array()
    .of(Yup.mixed())
    .min(1, "At least one niche is required")
    .required("At least one niche is required"),
  // actionTypes: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       type: Yup.string().required("Action type is required"),
  //       amount_type: Yup.string().required("Commission type is required"),
  //       amount: Yup.number()
  //         .positive("Commission amount must be a positive number")
  //         .nullable()
  //         .required("Commission amount is required"),
  //       currency: Yup.string().when("type", {
  //         is: (val) => val === "FIXED",
  //         then: (schema) => schema.required("Commission currency is required"),
  //         otherwise: (schema) => schema.notRequired(),
  //       }),
  //     }),
  //   )
  //   .min(1, "At least one action type is required")
  //   .required("Action type is required"),

  // epc: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       day: Yup.number().required("Day is required"),
  //       currency: Yup.string().required("Currency is required"),
  //       amount: Yup.number().required("Amount is required"),
  //       amount_in_usd: Yup.number().required("USD amount is required"),
  //     }),
  //   )
  //   .min(1, "At least one epc is required")
  //   .required("EPC is required"),
});

export const redeemCodeSchema = Yup.object().shape({
  redeem_code: Yup.string().required("Code is required"),
});
