import { VscLoading } from "react-icons/vsc";

const OptionsLoading = ({ options, isLoading }) => {
  return (
    <div className="flex flex-wrap items-center justify-center gap-2">
      <VscLoading className="h-5 w-5 animate-spin" />

      <p className="text-wrap break-words text-sm font-medium">
        {options?.length && isLoading ? "Fetching new" : "Loading"} options
      </p>
    </div>
  );
};

export default OptionsLoading;
