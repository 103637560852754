import AddOrEditActionTypeForm from "../components/forms/AddOrEditActionTypeForm";
import AddOrEditEpcForm from "../components/forms/AddOrEditEpcForm";
import { useModal } from "../modal-by-dipta-sikder/core/ModalContext";
import MuiModal from "../modal-by-dipta-sikder/modal/Mui/MuiModal";

const AddOrEditEpcModal = () => {
  const { data } = useModal();
  const isEditMode = data?.isEditMode;

  return (
    <MuiModal currentModalName={"AddOrEditEpcModal"} className="">
      <MuiModal.Title
        title={isEditMode ? "Update Epc" : "Add Epc"}
        className="!px-4 sm:!px-6"
      />

      <AddOrEditEpcForm />
    </MuiModal>
  );
};

export default AddOrEditEpcModal;
