import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { initialAddOrEditActionTypeFormValues } from "./utils";
import { addOrEditActionTypeFormSchema } from "./validator";

const useAddOrEditActionTypeForm = () => {
  const addOrEditActionTypeFormHookProps = useForm({
    defaultValues: initialAddOrEditActionTypeFormValues,
    reValidateMode: "onChange",
    mode: "onBlur",
    resolver: yupResolver(addOrEditActionTypeFormSchema),
  });

  return {
    addOrEditActionTypeFormHookProps,
    initialValue: initialAddOrEditActionTypeFormValues,
  };
};

export default useAddOrEditActionTypeForm;
