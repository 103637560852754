import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { initialAffiliateProgramFormValues } from "./utils";
import { affiliateProgramFormSchema } from "./validator";

const useAffiliateProgramForm = () => {
  const affiliateProgramFormHookProps = useForm({
    defaultValues: initialAffiliateProgramFormValues,
    reValidateMode: "onChange",
    mode: "onBlur",
    resolver: yupResolver(affiliateProgramFormSchema),
  });

  const epcArrayHookProps = useFieldArray({
    control: affiliateProgramFormHookProps?.control,
    name: "epc",
  });

  return {
    affiliateProgramFormHookProps,
    initialValue: initialAffiliateProgramFormValues,
    epcArrayHookProps,
  };
};

export default useAffiliateProgramForm;
