import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedService: null,
  selectedKeyword: null,
};

export const utilitySlice = createSlice({
  name: "utility",
  initialState,
  reducers: {
    setSelectedService: (state, { payload }) => {
      state.selectedService = payload;
    },
    getSelectedKeywords: (state, { payload }) => {
      // console.log(payload);
      state.selectedKeyword = payload;
    },
  },
});

export const { setSelectedService, getSelectedKeywords } = utilitySlice.actions;

export default utilitySlice.reducer;
