// import { currencies } from "country-data";
export const initialAffiliateProgramFormValues = {
  affiliate_marketplace: "",
  logo: null,
  product_name: "",
  niche: [],
  actionTypes: [
    // { type: "SALE", amount_type: "PERCENTAGE", currency: "USD", amount: 40.0 },
  ],
  // commissionType: "",
  // commissionCurrency: "USD",
  // commission: 0,
  epc: [
    // {
    //   day: 7,
    //   currency: "BDT",
    //   amount: 300,
    //   amount_in_usd: 3,
    // },
  ],
};

export let currencyOptions = [
  {
    label: "United States dollar - USD($)",
    value: "USD",
  },
  {
    label: "Euro - EUR(€)",
    value: "EUR",
  },
  {
    label: "Pound sterling - GBP(£)",
    value: "GBP",
  },
  {
    label: "Bangladeshi taka - BDT(৳)",
    value: "BDT",
  },
  {
    label: "Indian rupee - INR(₹)",
    value: "INR",
  },
  {
    label: "Australian dollar - AUD($)",
    value: "AUD",
  },
  {
    label: "Canadian dollar - CAD($)",
    value: "CAD",
  },
  {
    label: "Singapore dollar - SGD($)",
    value: "SGD",
  },
  {
    label: "Japanese yen - JPY(¥)",
    value: "JPY",
  },
  {
    label: "Chinese yuan - CNY(¥)",
    value: "CNY",
  },
  {
    label: "Hong Kong dollar - HKD($)",
    value: "HKD",
  },
  {
    label: "New Zealand dollar - NZD($)",
    value: "NZD",
  },
  {
    label: "Swedish krona/kronor - SEK(kr)",
    value: "SEK",
  },
  {
    label: "South Korean won - KRW(₩)",
    value: "KRW",
  },
  {
    label: "Norwegian krone - NOK(kr)",
    value: "NOK",
  },
  {
    label: "Mexican peso - MXN($)",
    value: "MXN",
  },
  {
    label: "Brazilian real - BRL(R$)",
    value: "BRL",
  },
  {
    label: "Swiss franc - CHF(CHF)",
    value: "CHF",
  },
  {
    label: "Russian rouble - RUB(₽)",
    value: "RUB",
  },
  {
    label: "Turkish lira - TRY()",
    value: "TRY",
  },
  {
    label: "South African rand - ZAR(R)",
    value: "ZAR",
  },
  {
    label: "United Arab Emirates dirham - AED(AED)",
    value: "AED",
  },
  {
    label: "Saudi riyal - SAR(﷼)",
    value: "SAR",
  },
  {
    label: "Qatari riyal - QAR(﷼)",
    value: "QAR",
  },
  {
    label: "Omani rial - OMR(﷼)",
    value: "OMR",
  },
  {
    label: "Kuwaiti dinar - KWD(KWD)",
    value: "KWD",
  },
  {
    label: "Bahraini dinar - BHD(BHD)",
    value: "BHD",
  },
  {
    label: "Jordanian dinar - JOD(JOD)",
    value: "JOD",
  },
  {
    label: "Israeli new shekel - ILS(₪)",
    value: "ILS",
  },
  {
    label: "Egyptian pound - EGP(£)",
    value: "EGP",
  },
  {
    label: "Thai baht - THB(฿)",
    value: "THB",
  },
  {
    label: "Indonesian rupiah - IDR(Rp)",
    value: "IDR",
  },
  {
    label: "Malaysian ringgit - MYR(RM)",
    value: "MYR",
  },
  {
    label: "Philippine peso - PHP(₱)",
    value: "PHP",
  },
  {
    label: "Pakistani rupee - PKR(₨)",
    value: "PKR",
  },
  {
    label: "Sri Lankan rupee - LKR(₨)",
    value: "LKR",
  },
  {
    label: "Nepalese rupee - NPR(₨)",
    value: "NPR",
  },
  {
    label: "Maldivian rufiyaa - MVR(MVR)",
    value: "MVR",
  },
  {
    label: "Mauritian rupee - MUR(₨)",
    value: "MUR",
  },
  {
    label: "Seychelles rupee - SCR(₨)",
    value: "SCR",
  },
  {
    label: "Kenyan shilling - KES(KSh)",
    value: "KES",
  },
  {
    label: "Tanzanian shilling - TZS(TSh)",
    value: "TZS",
  },
  {
    label: "Ugandan shilling - UGX(USh)",
    value: "UGX",
  },
  {
    label: "Ghanaian cedi - GHS(GHS)",
    value: "GHS",
  },
  {
    label: "Zambian kwacha - ZMW(ZMW)",
    value: "ZMW",
  },
  {
    label: "Nigerian naira - NGN(₦)",
    value: "NGN",
  },
  {
    label: "CFA franc BEAC - XAF(XAF)",
    value: "XAF",
  },
  {
    label: "CFA franc BCEAO - XOF(XOF)",
    value: "XOF",
  },
  {
    label: "Afghan afghani - AFN(؋)",
    value: "AFN",
  },
  {
    label: "Albanian lek - ALL(L)",
    value: "ALL",
  },
  {
    label: "Algerian dinar - DZD(DZD)",
    value: "DZD",
  },
  {
    label: "Angolan kwanza - AOA(AOA)",
    value: "AOA",
  },
  {
    label: "Argentine peso - ARS($)",
    value: "ARS",
  },
  {
    label: "Armenian dram - AMD(AMD)",
    value: "AMD",
  },
  {
    label: "Aruban florin - AWG(ƒ)",
    value: "AWG",
  },
  {
    label: "Azerbaijani manat - AZN(₼)",
    value: "AZN",
  },
  {
    label: "Bahamian dollar - BSD($)",
    value: "BSD",
  },
  {
    label: "Barbados dollar - BBD($)",
    value: "BBD",
  },
  {
    label: "Belarusian ruble - BYR(p.)",
    value: "BYR",
  },
  {
    label: "Belize dollar - BZD(BZ$)",
    value: "BZD",
  },
  {
    label: "Bermudian dollar (customarily known as Bermuda dollar) - BMD($)",
    value: "BMD",
  },
  {
    label: "Bhutanese ngultrum - BTN(BTN)",
    value: "BTN",
  },
  {
    label: "Bolivian Mvdol (funds code) - BOV(BOV)",
    value: "BOV",
  },
  {
    label: "Boliviano - BOB(Bs.)",
    value: "BOB",
  },
  {
    label: "Bosnia and Herzegovina convertible mark - BAM(KM)",
    value: "BAM",
  },
  {
    label: "Botswana pula - BWP(P)",
    value: "BWP",
  },
  {
    label: "Brunei dollar - BND($)",
    value: "BND",
  },
  {
    label: "Bulgarian lev - BGN(лв)",
    value: "BGN",
  },
  {
    label: "Burundian franc - BIF(BIF)",
    value: "BIF",
  },
  {
    label: "Cambodian riel - KHR(៛)",
    value: "KHR",
  },
  {
    label: "Cape Verde escudo - CVE(CVE)",
    value: "CVE",
  },
  {
    label: "Cayman Islands dollar - KYD($)",
    value: "KYD",
  },
  {
    label: "CFP franc - XPF(XPF)",
    value: "XPF",
  },
  {
    label: "Chilean peso - CLP($)",
    value: "CLP",
  },
  {
    label: "Code reserved for testing purposes - XTS(XTS)",
    value: "XTS",
  },
  {
    label: "Colombian peso - COP($)",
    value: "COP",
  },
  {
    label: "Comoro franc - KMF(KMF)",
    value: "KMF",
  },
  {
    label: "Congolese franc - CDF(CDF)",
    value: "CDF",
  },
  {
    label: "Costa Rican colon - CRC(₡)",
    value: "CRC",
  },
  {
    label: "Croatian kuna - HRK(kn)",
    value: "HRK",
  },
  {
    label: "Cuban convertible peso - CUC(CUC)",
    value: "CUC",
  },
  {
    label: "Cuban peso - CUP(₱)",
    value: "CUP",
  },
  {
    label: "Czech koruna - CZK(Kč)",
    value: "CZK",
  },
  {
    label: "Danish krone - DKK(kr)",
    value: "DKK",
  },
  {
    label: "Djiboutian franc - DJF(DJF)",
    value: "DJF",
  },
  {
    label: "Dominican peso - DOP(RD$)",
    value: "DOP",
  },
  {
    label: "East Caribbean dollar - XCD($)",
    value: "XCD",
  },
  {
    label: "Eritrean nakfa - ERN(ERN)",
    value: "ERN",
  },
  {
    label: "Ethiopian birr - ETB(ETB)",
    value: "ETB",
  },
  {
    label: "European Composite Unit (EURCO) (bond market unit) - XBA(XBA)",
    value: "XBA",
  },
  {
    label: "European Monetary Unit (E.M.U.-6) (bond market unit) - XBB(XBB)",
    value: "XBB",
  },
  {
    label:
      "European Unit of Account 17 (E.U.A.-17) (bond market unit) - XBD(XBD)",
    value: "XBD",
  },
  {
    label:
      "European Unit of Account 9 (E.U.A.-9) (bond market unit) - XBC(XBC)",
    value: "XBC",
  },
  {
    label: "Falkland Islands pound - FKP(£)",
    value: "FKP",
  },
  {
    label: "Fiji dollar - FJD($)",
    value: "FJD",
  },
  {
    label: "Gambian dalasi - GMD(GMD)",
    value: "GMD",
  },
  {
    label: "Georgian lari - GEL(GEL)",
    value: "GEL",
  },
  {
    label: "Gibraltar pound - GIP(£)",
    value: "GIP",
  },
  {
    label: "Gold (one troy ounce) - XAU(XAU)",
    value: "XAU",
  },
  {
    label: "Guatemalan quetzal - GTQ(Q)",
    value: "GTQ",
  },
  {
    label: "Guinean franc - GNF(GNF)",
    value: "GNF",
  },
  {
    label: "Guyanese dollar - GYD($)",
    value: "GYD",
  },
  {
    label: "Haitian gourde - HTG(HTG)",
    value: "HTG",
  },
  {
    label: "Honduran lempira - HNL(L)",
    value: "HNL",
  },
  {
    label: "Hungarian forint - HUF(Ft)",
    value: "HUF",
  },
  {
    label: "Icelandic króna - ISK(kr)",
    value: "ISK",
  },
  {
    label: "Iranian rial - IRR(﷼)",
    value: "IRR",
  },
  {
    label: "Iraqi dinar - IQD(IQD)",
    value: "IQD",
  },
  {
    label: "Jamaican dollar - JMD(J$)",
    value: "JMD",
  },
  {
    label: "Kazakhstani tenge - KZT(лв)",
    value: "KZT",
  },
  {
    label: "Kyrgyzstani som - KGS(лв)",
    value: "KGS",
  },
  {
    label: "Lao kip - LAK(₭)",
    value: "LAK",
  },
  {
    label: "Latvian lats - LVL(Ls)",
    value: "LVL",
  },
  {
    label: "Lebanese pound - LBP(£)",
    value: "LBP",
  },
  {
    label: "Lesotho loti - LSL(LSL)",
    value: "LSL",
  },
  {
    label: "Liberian dollar - LRD($)",
    value: "LRD",
  },
  {
    label: "Libyan dinar - LYD(LYD)",
    value: "LYD",
  },
  {
    label: "Lithuanian litas - LTL(Lt)",
    value: "LTL",
  },
  {
    label: "Macanese pataca - MOP(MOP)",
    value: "MOP",
  },
  {
    label: "Macedonian denar - MKD(ден)",
    value: "MKD",
  },
  {
    label: "Malagasy ariary - MGA(MGA)",
    value: "MGA",
  },
  {
    label: "Malawian kwacha - MWK(MWK)",
    value: "MWK",
  },
  {
    label: "Mauritanian ouguiya - MRO(MRO)",
    value: "MRO",
  },
  {
    label: "Mexican Unidad de Inversion (UDI) (funds code) - MXV(MXV)",
    value: "MXV",
  },
  {
    label: "Moldovan leu - MDL(MDL)",
    value: "MDL",
  },
  {
    label: "Mongolian tugrik - MNT(₮)",
    value: "MNT",
  },
  {
    label: "Moroccan dirham - MAD(MAD)",
    value: "MAD",
  },
  {
    label: "Mozambican metical - MZN(MT)",
    value: "MZN",
  },
  {
    label: "Myanma kyat - MMK(MMK)",
    value: "MMK",
  },
  {
    label: "Namibian dollar - NAD($)",
    value: "NAD",
  },
  {
    label: "Netherlands Antillean guilder - ANG(ƒ)",
    value: "ANG",
  },
  {
    label: "New Taiwan dollar - TWD(NT$)",
    value: "TWD",
  },
  {
    label: "Nicaraguan córdoba - NIO(C$)",
    value: "NIO",
  },
  {
    label: "No currency - XXX(XXX)",
    value: "XXX",
  },
  {
    label: "North Korean won - KPW(₩)",
    value: "KPW",
  },
  {
    label: "Palladium (one troy ounce) - XPD(XPD)",
    value: "XPD",
  },
  {
    label: "Panamanian balboa - PAB(B/.)",
    value: "PAB",
  },
  {
    label: "Papua New Guinean kina - PGK(PGK)",
    value: "PGK",
  },
  {
    label: "Paraguayan guaraní - PYG(Gs)",
    value: "PYG",
  },
  {
    label: "Peruvian nuevo sol - PEN(S/.)",
    value: "PEN",
  },
  {
    label: "Platinum (one troy ounce) - XPT(XPT)",
    value: "XPT",
  },
  {
    label: "Polish złoty - PLN(zł)",
    value: "PLN",
  },
  {
    label: "Romanian new leu - RON(lei)",
    value: "RON",
  },
  {
    label: "Rwandan franc - RWF(RWF)",
    value: "RWF",
  },
  {
    label: "Saint Helena pound - SHP(£)",
    value: "SHP",
  },
  {
    label: "Samoan tala - WST(WST)",
    value: "WST",
  },
  {
    label: "São Tomé and Príncipe dobra - STD(STD)",
    value: "STD",
  },
  {
    label: "Serbian dinar - RSD(Дин.)",
    value: "RSD",
  },
  {
    label: "Sierra Leonean leone - SLL(SLL)",
    value: "SLL",
  },
  {
    label: "Silver (one troy ounce) - XAG(XAG)",
    value: "XAG",
  },
  {
    label: "Solomon Islands dollar - SBD($)",
    value: "SBD",
  },
  {
    label: "Somali shilling - SOS(S)",
    value: "SOS",
  },
  {
    label: "South Sudanese pound - SSP(SSP)",
    value: "SSP",
  },
  {
    label: "Special drawing rights - XDR(XDR)",
    value: "XDR",
  },
  {
    label: "Sudanese pound - SDG(SDG)",
    value: "SDG",
  },
  {
    label: "Surinamese dollar - SRD($)",
    value: "SRD",
  },
  {
    label: "Swazi lilangeni - SZL(SZL)",
    value: "SZL",
  },
  {
    label: "Syrian pound - SYP(£)",
    value: "SYP",
  },
  {
    label: "Tajikistani somoni - TJS(TJS)",
    value: "TJS",
  },
  {
    label: "Tongan paʻanga - TOP(TOP)",
    value: "TOP",
  },
  {
    label: "Trinidad and Tobago dollar - TTD(TT$)",
    value: "TTD",
  },
  {
    label: "Tunisian dinar - TND(TND)",
    value: "TND",
  },
  {
    label: "Turkmenistani manat - TMT(TMT)",
    value: "TMT",
  },
  {
    label: "UIC franc (special settlement currency) - XFU(XFU)",
    value: "XFU",
  },
  {
    label: "Ukrainian hryvnia - UAH(₴)",
    value: "UAH",
  },
  {
    label: "undefined - all(undefined)",
    value: "all",
  },
  {
    label: "Unidad de Fomento (funds code) - CLF(CLF)",
    value: "CLF",
  },
  {
    label: "Unidad de Valor Real - COU(COU)",
    value: "COU",
  },
  {
    label: "United States dollar (next day) (funds code) - USN(USN)",
    value: "USN",
  },
  {
    label:
      "United States dollar (same day) (funds code) (one source[who?] claims it is no longer used, but it is still on the ISO 4217-MA list) - USS(USS)",
    value: "USS",
  },
  {
    label:
      "Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code) - UYI(UYI)",
    value: "UYI",
  },
  {
    label: "Uruguayan peso - UYU($U)",
    value: "UYU",
  },
  {
    label: "Uzbekistan som - UZS(лв)",
    value: "UZS",
  },
  {
    label: "Vanuatu vatu - VUV(VUV)",
    value: "VUV",
  },
  {
    label: "Venezuelan bolívar fuerte - VEF(Bs)",
    value: "VEF",
  },
  {
    label: "Vietnamese dong - VND(₫)",
    value: "VND",
  },
  {
    label: "WIR Euro (complementary currency) - CHE(CHE)",
    value: "CHE",
  },
  {
    label: "WIR Franc (complementary currency) - CHW(CHW)",
    value: "CHW",
  },
  {
    label: "Yemeni rial - YER(﷼)",
    value: "YER",
  },
];

export const commissionTypeOptions = [
  {
    label: "Fixed",
    value: "FIXED",
  },
  {
    label: "Variable (%)",
    value: "PERCENTAGE",
  },
];

export const actionTypeOptions = [
  {
    label: "Sale",
    value: "SALE",
  },
  {
    label: "Lead",
    value: "LEAD",
  },
  {
    label: "Click",
    value: "CLICK",
  },
];

export const nicheSelectOptions = [
  { label: "Web Hosting", value: "web hosting" },
  { label: "Web Hosting", value: "web hosting" },
  { label: "Recycling", value: "recycling" },
  { label: "Domain Registration", value: "domain registration" },
  { label: "Peripherals", value: "peripherals" },
  { label: "Productivity Tools", value: "productivity tools" },
  { label: "Furniture", value: "furniture" },
];

export const durationOptions = [
  {
    label: "Last 24 hours",
    value: "today",
  },
  {
    label: "Last 7 days",
    value: "last_7_days",
  },
  {
    label: "Last 30 days",
    value: "last_30_days",
  },
  {
    label: "Last 90 days",
    value: "last_90_days",
  },
  {
    label: "Last 180 days",
    value: "last_180_days",
  },
  {
    label: "Last 365 days",
    value: "last_365_days",
  },
];
