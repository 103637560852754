import { Skeleton, Typography } from "@mui/material";
import React from "react";

const FallbackComponent = () => {
  return (
    <div className="flex">
      <div className="min-h-screen w-80 border-0 border-r bg-[#FFFFFF] p-4">
        <Typography component="div" variant={"h3"}>
          <Skeleton />
        </Typography>
        <Typography
          component="div"
          variant={"caption"}
          sx={{ width: "100px", mt: "18px" }}
        >
          <Skeleton />
        </Typography>
        <Typography
          component="div"
          variant={"caption"}
          sx={{ width: "100px", my: "4px" }}
        >
          <Skeleton />
        </Typography>
        <Typography component="div" variant={"caption"} sx={{ width: "150px" }}>
          <Skeleton />
        </Typography>

        <div className="mt-8 flex items-center gap-2">
          <Typography component="div" variant={"h3"} sx={{ width: "33px" }}>
            <Skeleton />
          </Typography>
          <Typography
            component="div"
            variant={"caption"}
            sx={{ width: "150px" }}
          >
            <Skeleton />
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <Typography component="div" variant={"h3"} sx={{ width: "33px" }}>
            <Skeleton />
          </Typography>
          <Typography
            component="div"
            variant={"caption"}
            sx={{ width: "90px" }}
          >
            <Skeleton />
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <Typography component="div" variant={"h3"} sx={{ width: "33px" }}>
            <Skeleton />
          </Typography>
          <Typography
            component="div"
            variant={"caption"}
            sx={{ width: "70px" }}
          >
            <Skeleton />
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <Typography component="div" variant={"h3"} sx={{ width: "33px" }}>
            <Skeleton />
          </Typography>
          <Typography
            component="div"
            variant={"caption"}
            sx={{ width: "130px" }}
          >
            <Skeleton />
          </Typography>
        </div>
      </div>
      <div className="flex min-h-screen w-full items-start justify-between bg-[#F4F5F9] p-4">
        <div className="flex items-center gap-2">
          <Typography component="div" variant={"h3"} sx={{ width: "33px" }}>
            <Skeleton />
          </Typography>
          <div className="hidden md:block">
            <Typography
              component="div"
              variant={"caption"}
              sx={{ width: "130px" }}
            >
              <Skeleton />
            </Typography>
          </div>
        </div>
        <div className="hidden md:block">
          <Typography
            component="div"
            variant={"caption"}
            sx={{ width: "130px", mt: "15px" }}
          >
            <Skeleton />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default FallbackComponent;
