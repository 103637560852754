import Button from "@mui/material/Button";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createPromiseToast } from "../../../core/utils/promiseToast";
import { useModal } from "../../../modal-by-dipta-sikder/core/ModalContext";
import {
  useCreateActionTypeMutation,
  useUpdateActionTypeByIdMutation,
} from "../../../redux/api/actionTypeApi";

import {
  addActionList,
  deleteAActionType,
} from "../../../redux/slices/affiliateSlice";
import CustomInput from "../../input/customInput";
import CustomSelect from "../../input/customSelect";
import useGetActionTypeOptions from "../AffiliateProgramForm/useGetActionTypeOptions";
import {
  commissionTypeOptions,
  currencyOptions,
} from "../AffiliateProgramForm/utils";
import useAddOrEditActionTypeForm from "./useAddOrEditActionTypeForm";

const AddOrEditActionTypeForm = () => {
  const { data, closeModal, openModal } = useModal();
  const isEditMode = data?.isEditMode;
  const [createActionType] = useCreateActionTypeMutation();
  const [updateActionTypeById] = useUpdateActionTypeByIdMutation();
  const { addOrEditActionTypeFormHookProps, initialValue } =
    useAddOrEditActionTypeForm();
  const dispatch = useDispatch();
  const { handleSubmit, reset, register, watch, control, formState } =
    addOrEditActionTypeFormHookProps;
  const { errors } = formState;
  console.log("errors: ", errors);

  const currentValues = watch();
  // console.log("watch: ", currentValues);
  console.log("watch: ", currentValues);

  const onSubmit = async (values) => {
    const toast = createPromiseToast();
    const { errorToast, loadingToast, successToast } = toast();
    try {
      loadingToast({
        message: `Executing ${isEditMode ? "update" : "add"} action type...`,
      });
      // console.log("form submit values: ", transformedValues);
      let result = isEditMode
        ? await updateActionTypeById({ id: data?.id, data: values }).unwrap()
        : await createActionType(values).unwrap();
      successToast({
        message: `Action type ${isEditMode ? "updated" : "added"} successfully`,
      });
      // console.log("res: ", result);
      dispatch(deleteAActionType(data?.id));
      dispatch(addActionList(result));
      reset(initialValue);
      closeModal();
    } catch (err) {
      console.log("err: ", err);
      errorToast({
        message:
          err?.data?.error_message ||
          `Failed to ${isEditMode ? "update" : "add"} action type`,
      });
    }
  };

  const { chooseActionTypeOptions } = useGetActionTypeOptions();

  useEffect(() => {
    if (isEditMode && data) {
      reset({ ...data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, data]);

  console.log("received data: ", data);

  return (
    <div className="!px-4 !pt-4 sm:!px-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
          <CustomSelect
            label="Action Type"
            name="type"
            placeholder="Select action type"
            className="w-full"
            control={control}
            id="value"
            val="label"
            options={chooseActionTypeOptions}
            // isLoading={isChooseActionTypeOptionsLoading}
            // enableSearch
            // enableAddNewOption
            // onAddNewOptionClick={() => {
            //   openModal("AddMarketplaceModal");
            // }}
            errorMessage={errors?.type?.message}
          />

          <CustomSelect
            label="Commission Type"
            name="amount_type"
            placeholder="Select commission type"
            min={0}
            className="w-full"
            control={control}
            id="value"
            val="label"
            options={commissionTypeOptions}
            errorMessage={errors?.amount_type?.message}
          />

          {currentValues?.amount_type === "FIXED" && (
            <CustomSelect
              label="Commission Currency"
              name="currency"
              placeholder="Select commission currency"
              className="w-full"
              control={control}
              id="value"
              val="label"
              options={currencyOptions}
              enableSearch
              errorMessage={errors?.currency?.message}
            />
          )}

          <CustomInput
            label="Commission"
            type="number"
            step="0.000001"
            // name="amount"
            placeholder="Enter commission amount"
            {...register("amount", { valueAsNumber: true })}
            errorMessage={errors?.commission?.message}
          />
        </div>

        <div className="my-5">
          <Button variant="contained" fullWidth type="submit">
            {isEditMode ? "Update" : "Create"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddOrEditActionTypeForm;
