import Button from "@mui/material/Button";
import { createPromiseToast } from "../../../core/utils/promiseToast";
import { useUpdateSmallAIFeatureMutation } from "../../../redux/api/taskApi";
import RenderData from "../../common/RenderData";
import ErrorMessage from "../../input/common-components/FormFieldWrapper/ErrorMessage";
import CustomInput from "../../input/customInput";
import Actions from "../DynamicForm/Actions";
import useManageMarketplaceForm from "./useManageMarketplaceForm";
import { useGetAllAffiliateMarketplaceQuery } from "../../../redux/api/affiliateMarketplaceApi";
import { useModal } from "../../../modal-by-dipta-sikder/core/ModalContext";

const ManageMarketplaceForm = () => {
  const { data, closeModal } = useModal();
  const [updateSmallAIFeature] = useUpdateSmallAIFeatureMutation();
  const {
    manageMarketplaceFormHookProps,
    marketplacesArrayHookProps,
    initialValue,
  } = useManageMarketplaceForm();

  const { handleSubmit, reset, register, watch, formState } =
    manageMarketplaceFormHookProps;
  const { errors } = formState;
  console.log("errors: ", errors);

  const { fields, append, prepend, remove, swap, insert } =
    marketplacesArrayHookProps;

  const currentValues = watch();
  // console.log("watch: ", currentValues);
  console.log("watch: ", currentValues);

  const onSubmit = async (values) => {
    const toast = createPromiseToast();
    const { errorToast, loadingToast, successToast } = toast();
    try {
      loadingToast({ message: "Updating marketplace..." });
      // const transformedValues = {
      //   ...values,
      //   GPTVersion: {
      //     options: values?.GPTVersion?.options,
      //     defaultValue: values?.GPTVersion?.defaultValue,
      //   },
      //   task_id: id,
      // };
      // console.log("form submit values: ", transformedValues);
      // const res = await updateSmallAIFeature({}).unwrap();
      successToast({ message: "Marketplace updated successfully" });
      // console.log("res: ", res);
      reset();
      closeModal();
      // if (backToPage === 1 && backToSize === 10) {
      //   navigate(ROUTES.shortFeatures);
      // } else {
      //   navigate(
      //     `${ROUTES.shortFeatures}?page=${backToPage}&size=${backToSize}`,
      //   );
      // }
    } catch (err) {
      console.log("err: ", err);
      errorToast({
        message: err?.data?.error_message || "Failed to update marketplace",
      });
    }
  };

  const {
    data: getAllAffiliateMarketplaceRes,
    ...getAllAffiliateMarketplaceApiState
  } = useGetAllAffiliateMarketplaceQuery();
  // useEffect(() => {
  //   if (getSmallAIFeatureByIdData) {
  //     const values = { ...(getSmallAIFeatureByIdData ?? {}) };
  //     const transformedValues = {
  //       ...values,
  //       // dynamicFields: values?.dynamicFields?.map((field) => {
  //       // return {
  //       //     ...field,
  //       //     key: field?.key,
  //       //     [field?.key]: field?.defaultValue,
  //       // };
  //       // }),
  //     };
  //     reset(transformedValues);
  //   }
  // }, [getSmallAIFeatureByIdData]);

  const content = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4">
        {/* Dynamic Form Start */}
        {fields?.map((field, index) => {
          const currentIndexErrors = errors?.marketplaces?.[index];
          // const currentIndexValues = watch(`marketplaces.${index}`);
          // console.log("currentIndexValues: ", currentIndexValues);

          return (
            <div
              className="flex flex-wrap items-start justify-center gap-x-3 gap-y-4 rounded-lg border border-gray-300 p-1.5 shadow-md md:p-5"
              key={field?.id}
            >
              <div className="flex-grow">
                <CustomInput
                  label={"Title"}
                  type={"text"}
                  placeholder={"Enter title"}
                  {...register(`marketplaces.${index}.title`)}
                  errorMessage={currentIndexErrors?.title?.message}
                />
              </div>

              <Actions
                arr={fields}
                index={index}
                onDelete={({ index, data }) => {
                  return remove(index);
                }}
                onSwapUp={({ from, to, fromData, toData }) => {
                  return swap(from, to);
                }}
                onSwapDown={({ from, to, fromData, toData }) => {
                  return swap(from, to);
                }}
                onInsertBelowThisRow={({ targetIndex }) => {
                  return insert(targetIndex, {
                    title: "",
                  });
                }}
                hideEdit
              />
            </div>
          );
        })}

        {!!errors?.marketplaces?.root?.message && (
          <ErrorMessage errorMessage={errors?.marketplaces?.root?.message} />
        )}

        <div className="my-2 flex flex-wrap items-center justify-center gap-2">
          {fields?.length > 1 && (
            <Button
              onClick={() => {
                prepend({
                  title: "",
                });
              }}
              type="button"
              className="w-full max-w-[150px] sm:max-w-sm"
              variant="contained"
              aria-label="Insert Field At End of the Form"
              fullWidth
            >
              Add New Field At Start
            </Button>
          )}

          <Button
            onClick={() => {
              append({
                title: "",
              });
            }}
            type="button"
            className="w-full max-w-[150px] sm:max-w-sm"
            variant="contained"
            aria-label="Insert Field At End of the Form"
            fullWidth
          >
            {fields?.length > 1 ? "Add New Field at End" : "Add New Field"}
          </Button>
        </div>
        {/* Dynamic Form End */}
      </div>

      <div className="my-5">
        <Button variant="contained" fullWidth type="submit">
          Update
        </Button>
      </div>
    </form>
  );

  return (
    <div className="!px-4 !pt-4 sm:!px-6">
      <RenderData
        // data={getSmallAIFeatureByIdData}
        // apiState={getSmallAIFeatureByIdApiState}
        noContentFound={{
          message:
            "No previously saved marketplace found. You may create one or many.",
          afterContent: <div className="mt-5">{content}</div>,
        }}
      >
        {content}
      </RenderData>
    </div>
  );
};

export default ManageMarketplaceForm;
