import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useCallback } from "react";
import { useModal } from "../../core/ModalContext";
import { cn } from "../../../core/utils/cn";
import Title from "./Title";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
};

const MuiModal = ({
  children,
  currentModalName,
  disableEscapeKeyCloseModal,
  enableBackdropClickCloseModal,
  className,
}) => {
  const { isOpen, modalName, closeModal, getIsThisModalOpen } = useModal();

  const handleClose = useCallback(
    ({
      closeModal,
      disableEscapeKeyCloseModal,
      enableBackdropClickCloseModal,
    }) =>
      (event, reason) => {
        if (reason === "escapeKeyDown" && !disableEscapeKeyCloseModal)
          closeModal();
        if (reason === "backdropClick" && enableBackdropClickCloseModal)
          closeModal();
      },
    [],
  );

  return (
    <Modal
      open={getIsThisModalOpen({
        isOpen,
        modalName,
        currentModalName,
      })}
      onClose={handleClose({
        closeModal,
        disableEscapeKeyCloseModal,
        enableBackdropClickCloseModal,
      })}
      aria-labelledby={"modal-modal-title"}
      aria-describedby={"modal-modal-description"}
    >
      <Box
        sx={modalStyle}
        className={cn(
          "!transition-linear relative max-h-[95%] w-full max-w-[95%] !overflow-y-auto !scroll-smooth rounded-xl border-none pb-2 outline-none xl:max-w-[65%]",
          className,
        )}
      >
        {children}
      </Box>
    </Modal>
  );
};

MuiModal.Title = Title;

export default MuiModal;
