import { cn } from "../../../../core/utils/cn";

const Label = ({ label, htmlFor, className, ...restProps }) => {
  return (
    <label
      className={cn(
        "text-text-gray mb-1.5 ml-[1px] flex items-center text-wrap break-all text-lg font-medium",
        className,
      )}
      htmlFor={htmlFor}
      {...restProps}
    >
      {label}
    </label>
  );
};

export default Label;
