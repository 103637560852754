import { apiSlice } from ".";
import { generateQueryString } from "../../core/utils/generateQueryString";

export const epcApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllEpc: builder.query({
      query: () => {
        const { queryString } = generateQueryString({
          queryObject: {
            // page_size: 15, page: 1
          },
        });
        return {
          url: `/seo-analysis/v1/epc/${queryString}`,
        };
      },
      providesTags: ["getAllEpc"],
    }),

    getEpcById: builder.query({
      query: (id) => {
        return {
          url: `/seo-analysis/v1/epc/${id}`,
        };
      },
      providesTags: ["getEpcById"],
    }),

    createEpc: builder.mutation({
      query: (data) => {
        return {
          url: "/seo-analysis/v1/epc/",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["getAllEpc", "getEpcById"],
    }),

    updateEpcById: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/seo-analysis/v1/epc/${id}/`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["getAllEpc", "getEpcById"],
    }),

    deleteEpcById: builder.mutation({
      query: (id) => {
        return {
          url: `/seo-analysis/v1/epc/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["getAllEpc", "getEpcById"],
    }),
  }),
});

export const {
  useGetAllEpcQuery,
  useGetEpcByIdQuery,
  useCreateEpcMutation,
  useUpdateEpcByIdMutation,
  useDeleteEpcByIdMutation,
} = epcApiSlice;
