import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editorContent: "",
  wordCount: 0,
};

export const editorSilce = createSlice({
  name: "editor",
  initialState,
  reducers: {
    setEditorContent: (state, { payload }) => {
      state.editorContent = payload;
    },
    setWordCount: (state, { payload }) => {
      // console.log(payload);
      state.wordCount = payload;
    },
  },
});

export const { setEditorContent, setWordCount } = editorSilce.actions;

export default editorSilce.reducer;
