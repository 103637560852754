import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSignedIn: false,
  token: null,
  user_info: null,
  referred_by: null,
  userStats: null,
  referalInfo: null,
  referralChartData: null,
  referralTableData: null,
  lowCredit: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.isSignedIn = true;
      state.token = payload.token;
      state.user_info = payload.user_info;
    },
    userInfoUpdate: (state, { payload }) => {
      state.user_info = payload;
    },
    setRerredBy: (state, { payload }) => {
      state.referred_by = {
        time: Date(Date.now()),
        ref_code: payload,
      };
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setStats: (state, { payload }) => {
      state.userStats = payload;
    },
    setReferalInfo: (state, { payload }) => {
      state.referalInfo = payload;
    },
    setReferralChartData: (state, { payload }) => {
      state.referralChartData = payload;
    },
    setReferralTableData: (state, { payload }) => {
      state.referralTableData = payload;
    },
    setLowCredit: (state, { payload }) => {
      state.lowCredit = payload;
    },
    logoutAction: (state) => initialState,
  },
});

export const {
  login,
  userInfoUpdate,
  setRerredBy,
  setStats,
  setReferalInfo,
  setToken,
  logoutAction,
  setReferralChartData,
  setReferralTableData,
  setLowCredit,
} = authSlice.actions;

export default authSlice.reducer;
