import AddNicheForm from "../components/forms/AddNicheForm";
import ManageMarketplaceForm from "../components/forms/ManageMarketplaceForm";
import { useModal } from "../modal-by-dipta-sikder/core/ModalContext";
import MuiModal from "../modal-by-dipta-sikder/modal/Mui/MuiModal";

const AddNicheModal = () => {
  const { data } = useModal();

  return (
    <MuiModal currentModalName={"AddNicheModal"} className="">
      <MuiModal.Title title={"Add Niche"} className="!px-4 sm:!px-6" />

      <AddNicheForm />
    </MuiModal>
  );
};

export default AddNicheModal;
