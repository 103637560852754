import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { initialAddOrEditEpcFormValues } from "./utils";
import { addOrEditEpcFormSchema } from "./validator";

const useAddOrEditEpcForm = () => {
  const addOrEditEpcFormHookProps = useForm({
    defaultValues: initialAddOrEditEpcFormValues,
    reValidateMode: "onChange",
    mode: "onBlur",
    resolver: yupResolver(addOrEditEpcFormSchema),
  });

  return {
    addOrEditEpcFormHookProps,
    initialValue: initialAddOrEditEpcFormValues,
  };
};

export default useAddOrEditEpcForm;
