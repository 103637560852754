import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { manageMarketplaceFormSchema } from "./validator";
import { initialManageMarketplaceFormValues } from "./utils";

const useManageMarketplaceForm = () => {
  const manageMarketplaceFormHookProps = useForm({
    defaultValues: initialManageMarketplaceFormValues,
    reValidateMode: "onChange",
    mode: "onBlur",
    resolver: yupResolver(manageMarketplaceFormSchema),
  });

  const marketplacesArrayHookProps = useFieldArray({
    control: manageMarketplaceFormHookProps?.control,
    name: "marketplaces",
  });

  return {
    manageMarketplaceFormHookProps,
    initialValue: initialManageMarketplaceFormValues,
    marketplacesArrayHookProps,
  };
};

export default useManageMarketplaceForm;
