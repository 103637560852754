import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: true,
};

export const sidebarSilce = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleDrawer: (state, { payload }) => {
      state.open = payload;
    },
  },
});

export const { toggleDrawer } = sidebarSilce.actions;

export default sidebarSilce.reducer;
