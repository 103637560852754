import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedUser: [],
};

export const mailSlice = createSlice({
  name: "mail",
  initialState,
  reducers: {
    setSelectedUser: (state, { payload }) => {
      // console.log(payload);
      state.selectedUser = payload;
    },
  },
});

export const { setSelectedUser } = mailSlice.actions;
export default mailSlice.reducer;
