export const initialManageMarketplaceFormValues = {
  marketplaces: [
    {
      title: "A",
    },
    {
      title: "B",
    },
  ],
};
