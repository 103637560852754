import { combineReducers } from "redux";
import { apiSlice } from "./api";
import adminReducer from "./slices/adminSlice";
import affiliateSlice from "./slices/affiliateSlice";
import authReducer from "./slices/authSlice";
import billingReducer from "./slices/billingSlice";
import editorReducer from "./slices/editorSlice";
import externalServiceSlice from "./slices/externalServiceSlice";
import mailSlice from "./slices/mailSlice";
import notificationReducer from "./slices/notificationSlice";
import sidebarReducer from "./slices/sidebarSlice";
import siteReducer from "./slices/siteSlice";
import taskReducer from "./slices/taskSlice";
import utilitySlice from "./slices/utilitySlice";
import whatsNewSlice from "./slices/whatsNewSlice";
import storage from "./storage";

const PERSIST_CONFIG_KEY = "affpilot";

export const reducers = combineReducers({
  auth: authReducer,
  task: taskReducer,
  site: siteReducer,
  admin: adminReducer,
  billing: billingReducer,
  notification: notificationReducer,
  sidebar: sidebarReducer,
  editor: editorReducer,
  utility: utilitySlice,
  mail: mailSlice,
  externalService: externalServiceSlice,
  whatsNew: whatsNewSlice,
  affiliate: affiliateSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export const persistConfig = {
  key: PERSIST_CONFIG_KEY,
  whitelist: [
    "auth",
    "admin",
    "billing",
    "task",
    "notification",
    "sidebar",
    "editor",
    "externalService",
    "site",
    "whatsNew",
    "affiliate",
  ],
  storage,
};
