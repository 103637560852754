import AddOrEditActionTypeForm from "../components/forms/AddOrEditActionTypeForm";
import { useModal } from "../modal-by-dipta-sikder/core/ModalContext";
import MuiModal from "../modal-by-dipta-sikder/modal/Mui/MuiModal";

const AddOrEditActionTypeModal = () => {
  const { data } = useModal();
  const isEditMode = data?.isEditMode;

  return (
    <MuiModal currentModalName={"AddOrEditActionTypeModal"} className="">
      <MuiModal.Title
        title={isEditMode ? "Update Action Type" : "Add Action Type"}
        className="!px-4 sm:!px-6"
      />

      <AddOrEditActionTypeForm />
    </MuiModal>
  );
};

export default AddOrEditActionTypeModal;
