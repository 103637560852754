import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: null,
  notificationCount: 0,
};

export const notificationSilce = createSlice({
  name: "notification",
  initialState,
  reducers: {
    getNotications: (state, { payload }) => {
      state.notifications = payload;
    },
    notificationCountUpdate: (state, { payload }) => {
      state.notificationCount = payload;
    },
  },
});

export const { getNotications, notificationCountUpdate } =
  notificationSilce.actions;

export default notificationSilce.reducer;
