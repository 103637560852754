import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { initialAddAffiliateMarketplaceFormValues } from "./utils";
import { addAffiliateMarketplaceFormSchema } from "./validator";

const useAddAffiliateMarketplaceForm = () => {
  const addAffiliateMarketplaceFormHookProps = useForm({
    defaultValues: initialAddAffiliateMarketplaceFormValues,
    reValidateMode: "onChange",
    mode: "onBlur",
    resolver: yupResolver(addAffiliateMarketplaceFormSchema),
  });

  return {
    addAffiliateMarketplaceFormHookProps,
    initialValue: initialAddAffiliateMarketplaceFormValues,
  };
};

export default useAddAffiliateMarketplaceForm;
