import { IoInformationCircleSharp } from "react-icons/io5";
import { cn } from "../../../../core/utils/cn";

const ErrorMessage = ({ errorMessage, className, ...restProps }) => {
  return (
    <div
      className={cn("mt-1 flex items-center gap-2", className)}
      {...restProps}
    >
      <IoInformationCircleSharp className="mr-1 h-4 w-4 text-red-600" />

      <p className="text-wrap break-all text-xs text-red-600">{errorMessage}</p>
    </div>
  );
};

export default ErrorMessage;
