import { cn } from "../../../../core/utils/cn";

const Hints = ({ hintsMessage, className, ...restProps }) => {
  return (
    <p className={cn("mb-3 text-sm break-all text-wrap", className)} {...restProps}>
      {hintsMessage}
    </p>
  );
};

export default Hints;
