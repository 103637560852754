import { useRef, useState } from "react";

const useDebounce = ({
  debouncedFunction = (props) => {},
  nonDebouncedFunction = (props) => {},
  valueSelector = (props) => {},
  delay = 800,
  value,
}) => {
  const timeOutId = useRef(null);
  const [nonDebouncedValue, setNonDebouncedValue] = useState(value);

  const debounce = (...args) => {
    if (timeOutId.current) clearTimeout(timeOutId.current);
    const value = valueSelector?.(args) ?? args;
    timeOutId.current = setTimeout(() => debouncedFunction?.(value), delay);
    setNonDebouncedValue(value);
    nonDebouncedFunction?.(value);
  };

  return { debounce, nonDebouncedValue };
};

export default useDebounce;
