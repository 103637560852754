import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wordpressList: {},
  amazonList: {},
  bloggerList: {},
  wordpressInfo: [],
  mediumList: null,
  isBlog: false,
  lastSite: { site_id: null, time: null },
  selectedCategory: null,
};

export const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    setWordpressList: (state, { payload }) => {
      state.wordpressList = payload;
    },
    setSelectedCategory: (state, { payload }) => {
      state.selectedCategory = payload;
    },
    setWordpressInfo: (state, { payload }) => {
      state.wordpressInfo = payload;
    },
    setAmazonList: (state, { payload }) => {
      state.amazonList = payload;
    },
    setBloggerList: (state, { payload }) => {
      state.bloggerList = payload;
    },
    setLastSite: (state, { payload }) => {
      state.lastSite = payload;
    },
    setIsBlog: (state, { payload }) => {
      state.isBlog = payload;
    },
    setMediumList: (state, { payload }) => {
      state.mediumList = payload;
    },
    siteLogoutAction: (state) => initialState,
  },
});

export const {
  setWordpressList,
  setWordpressInfo,
  setAmazonList,
  setBloggerList,
  siteLogoutAction,
  setLastSite,
  setIsBlog,
  setSelectedCategory,
  setMediumList,
} = siteSlice.actions;

export default siteSlice.reducer;
