import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { initialAddNicheFormValues } from "./utils";
import { addNicheFormSchema } from "./validator";

const useAddNicheForm = () => {
  const addNicheFormHookProps = useForm({
    defaultValues: initialAddNicheFormValues,
    reValidateMode: "onChange",
    mode: "onBlur",
    resolver: yupResolver(addNicheFormSchema),
  });

  return {
    addNicheFormHookProps,
    initialValue: initialAddNicheFormValues,
  };
};

export default useAddNicheForm;
