/* *created by Dipta Sikder*
|--------------------------------------------------
| About and Use of ModalContext.jsx:
| - The heart of the system
| - The product the file are ModalProvider & useModal hook
| - ModalProvider is the provider component that wraps the application and provides the context to the children.
| - useModal is the hook that is used to access the modal context at any child level.
| - This file should be imported and used in the root of the application.
|
|
| Caution:
| - Don't delete this file.
| - Don't delete the comments.
| - Don't delete the default export.
| - This file is required for the system to work.
| - Please don't modify the code unless you know what you are doing.
|
|
| Tips:
| - Give my repos a star if you find it helpful.
| - Follow me on github for more useful repos.
| - My github profile: https://github.com/dipta-sikder-775
| - Let's connected & Endorse me for React and related skills on LinkedIn: https://www.linkedin.com/in/dipta-sikder-775/
|--------------------------------------------------
*/

import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import ModalRegistry from "../modal-registry";

const ModalContext = createContext({
  isOpen: false,
  modalName: null,
  data: null,
  openModal: (modalName = null, data = null) => {},
  closeModal: () => {},
  getIsThisModalOpen: ({
    currentModalName = "",
    isOpen = false,
    modalName,
  }) => {},
});

export const useModal = () => useContext(ModalContext);

const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState({
    isOpen: false,
    modalName: null,
    data: null,
  });

  const openModal = useCallback((modalName = null, data = null) => {
    if (typeof modalName !== "string") {
      console.warn("Modal name should be a string.");
      return;
    }

    if (modalName === "") {
      console.warn("Modal name is required.");
      return;
    }

    if (!(modalName in ModalRegistry)) {
      console.warn(`Modal with name ${modalName} is not registered.`);
      return;
    }

    setModal({ isOpen: true, modalName, data });
  }, []);

  const closeModal = useCallback(() => {
    setModal({ isOpen: false, modalName: null, data: null });
  }, []);

  const getIsThisModalOpen = useCallback(
    ({ currentModalName = "", isOpen = false, modalName }) => {
      return (
        !!isOpen &&
        !!modalName &&
        !!currentModalName &&
        modalName === currentModalName
      );
    },
    [],
  );

  const modalContextProps = useMemo(() => {
    return {
      ...modal,
      openModal,
      closeModal,
      getIsThisModalOpen,
    };
  }, [modal, openModal, closeModal, getIsThisModalOpen]);

  return (
    <ModalContext.Provider value={modalContextProps}>
      {children}
    </ModalContext.Provider>
  );
};

export default memo(ModalProvider);
