import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedExternalService: null,
  selectedExternalServiceProduct: null,
};

export const externalServiceSlice = createSlice({
  name: "external-service",
  initialState,
  reducers: {
    setSelectedExternalService: (state, { payload }) => {
      state.selectedExternalService = payload;
    },
    setSelectedExternalServiceProduct: (state, { payload }) => {
      state.selectedExternalServiceProduct = payload;
    },
  },
});

export const { setSelectedExternalService, setSelectedExternalServiceProduct } =
  externalServiceSlice.actions;

export default externalServiceSlice.reducer;
