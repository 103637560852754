import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  whatsNewItems: [],
  dismissedItems: [],
  laterPressedTime: null,
};

export const whatsNewSlice = createSlice({
  name: "whatsNew",
  initialState,
  reducers: {
    setWhatsNewItems: (state, { payload }) => {
      state.whatsNewItems = payload;
    },
    setDismissedItems: (state, { payload }) => {
      state.dismissedItems = payload;
    },
    setLaterPressedTime: (state, { payload }) => {
      state.laterPressedTime = payload;
    },
    resetWhatsNew: (state) => initialState,
  },
});

export const {
  setWhatsNewItems,
  setDismissedItems,
  setLaterPressedTime,
  resetWhatsNew,
} = whatsNewSlice.actions;

export default whatsNewSlice.reducer;
