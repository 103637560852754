import { ThemeProvider, createTheme } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Suspense, lazy, useEffect } from "react";
import ReactGA from "react-ga4";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import resolveConfig from "tailwindcss/resolveConfig";
import "./App.css";
import FallbackComponent from "./components/fallbackComponent/FallbackComponent";
import { MinScreenProvider } from "./core/minScreen";
import DynamicModal from "./modal-by-dipta-sikder/core/DynamicModal";
import ModalProvider from "./modal-by-dipta-sikder/core/ModalContext";
import store from "./redux/store";
import tailwindConfig from "./theme/old-tailwind.config";
const config = resolveConfig(tailwindConfig);

let persistor = persistStore(store);
ReactGA.initialize("G-QKF9H5KDN4");
const AppRoutes = lazy(() => import("./appRoute/appRoutes"));
function App() {
  /*
    our main section it will execute first
    we are loading all initial setup from here,
    we are setting provider and persistgate for state management..
    we are using redux as state management
    * */
  let location = useLocation();
  useEffect(() => {
    // Google Analytics
    ReactGA.send({
      hitType: "pageview",
      page: location?.pathname,
      title: location?.pathname,
    });
  }, [location]);
  // console.log(ThemeProvider);
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Inter",
      },
    },
  });
  return (
    <MinScreenProvider screens={config?.theme?.screens}>
      <GoogleOAuthProvider
        clientId={
          process.env.REACT_APP_GOOGLE_CLIENT_ID
            ? process.env.REACT_APP_GOOGLE_CLIENT_ID
            : ""
        }
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<FallbackComponent />}>
              <ThemeProvider theme={theme}>
                <ModalProvider>
                  <DynamicModal />
                  <AppRoutes />
                </ModalProvider>
              </ThemeProvider>
            </Suspense>
            <ToastContainer limit={1} />
            <Toaster position="top-center" reverseOrder={false} />
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </MinScreenProvider>
  );
}

export default App;
