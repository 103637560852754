import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminUserList: {},
  adminToken: null,
  adminUserInfo: {},
  lastAdminPage: null,
  adminTaskUserInfo: {},
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminUserList: (state, { payload }) => {
      state.adminUserList = payload;
    },

    setAdminToken: (state, { payload }) => {
      state.adminToken = payload;
    },

    setAdminUserInfo: (state, { payload }) => {
      state.adminUserInfo = payload;
    },

    setLastAdminPage: (state, { payload }) => {
      state.lastAdminPage = payload;
    },

    setAdminTaskUserInfo: (state, { payload }) => {
      state.adminTaskUserInfo = payload;
    },

    adminLogoutAction: (state) => initialState,
  },
});

export const {
  setAdminUserList,
  setAdminUserInfo,
  adminLogoutAction,
  setAdminToken,
  setLastAdminPage,
  setAdminTaskUserInfo,
} = adminSlice.actions;

export default adminSlice.reducer;
