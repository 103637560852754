import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_EPI_URL } from "../../core/routes";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_EPI_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.auth?.token;
    // console.log(token);
    if (token) {
      headers.set("Authorization", `token ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  tagTypes: [
    "code-redeem",
    "medium-site-add",
    "ticket",
    "reply",
    "whats-new",
    "getSmallAIFeatures",
    "getSmallAIFeatureById",
    "user-info",
    "getAllAffiliateMarketplace",
    "getAffiliateMarketplaceById",
    "getAllNiche",
    "getNicheById",
    "getAllEpc",
    "getEpcById",
    "getAllAffiliateProduct",
    "getAffiliateProductById",
    "getAllActionType",
    "getActionTypeById",
    "getAllImage",
    "getImageById",
  ],
  endpoints: () => ({}),
});
