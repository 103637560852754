import AddMarketplaceModal from "../modals/AddMarketplaceModal";
import AddNicheModal from "../modals/AddNicheModal";
import AddOrEditActionTypeModal from "../modals/AddOrEditActionTypeModal";
import AddOrEditEpcModal from "../modals/AddOrEditEpcModal";
import ManageMarketplaceModal from "../modals/ManageMarketplaceModal";
import ManageNicheModal from "../modals/ManageNicheModal";

/* *created by Dipta Sikder*
|--------------------------------------------------
| - Modal Registry
| - This file is used to register all the modals that are created in the application.
| - Import modal components and write them inside ModalRegistry object.
|
|
|
| Caution: 
| - Don't delete this file.
| - Don't delete "ModalRegistry" object or modify it's name. 
| - Don't delete the comments.
| - Don't delete the default export.
| - This file is required for the system to work.
|--------------------------------------------------
*/
const ModalRegistry = {
  ManageMarketplaceModal,
  ManageNicheModal,
  AddNicheModal,
  AddMarketplaceModal,
  AddOrEditActionTypeModal,
  AddOrEditEpcModal,
};

export default ModalRegistry;
