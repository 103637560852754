import AddIcon from "@mui/icons-material/Add";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { cn } from "../../../core/utils/cn";
import FormFieldWrapper from "../../input/common-components/FormFieldWrapper";

const Actions = ({
  index,
  arr,
  onDelete = ({ index = -1, data = {} }) => {},
  onSwapUp = ({ from = -1, to = -1, fromData = {}, toData = {} }) => {},
  onSwapDown = ({ from = -1, to = -1, fromData = {}, toData = {} }) => {},
  onInsertBelowThisRow = ({ targetIndex = -1 }) => {},
  onEdit = ({ index = -1, data = {} }) => {},
  hideDelete,
  hideSwaps,
  hideInsert,
  hideEdit,
}) => {
  const arrayLength = arr?.length || 0;

  const handleDeleteClick = () => {
    if (index !== undefined && index !== null && index >= 0) {
      return onDelete?.({ index, data: arr?.[index] });
    }
  };

  const handleSwapUpClick = () => {
    // Ensure index is not the first element and is within the bounds of the fields array
    if (index > 0 && index < arrayLength) {
      try {
        return onSwapUp?.({
          from: index,
          to: index - 1,
          fromData: arr?.[index],
          toData: arr?.[index - 1],
        }); // Corrected to swap with the previous element to move it up
      } catch (error) {
        console.error("Error swapping up elements:", error);
      }
    }
  };

  const handleSwapDownClick = () => {
    // Ensure index is not the last element and is within the bounds of the fields array
    if (index >= 0 && index < arrayLength - 1) {
      try {
        return onSwapDown?.({
          from: index,
          to: index + 1,
          fromData: arr?.[index],
          toData: arr?.[index + 1],
        }); // Swap with the next element to move it down
      } catch (error) {
        console.error("Error swapping elements:", error);
      }
    }
  };

  const handleInsertBelowThisRowClick = () => {
    try {
      if (index !== undefined && index !== null && index >= 0)
        return onInsertBelowThisRow?.({ targetIndex: index + 1 });
    } catch (error) {
      console.log("Error inserting below this row:", error);
    }
  };

  const handleEdit = () => {
    onEdit?.({ index, data: arr?.[index] });
  };

  return (
    <FormFieldWrapper
      className="flex w-[180px] flex-col items-center justify-center"
      classNames={{
        labelClassName: "w-full flex flex-col items-center",
      }}
      label={index < arrayLength - 1 || arrayLength > 1 ? "Actions" : "Action"}
    >
      <div
        className={cn("mt-2 flex w-full items-center justify-start gap-2", {
          "justify-center": arrayLength === 1,
        })}
      >
        {!hideDelete && (
          <div>
            <Tooltip title="Delete this row">
              <IconButton
                onClick={handleDeleteClick}
                disabled={index === null || index === undefined || index < 0}
                className="h-fit"
                color="warning"
                type="button"
                aria-label="Delete row"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}

        {!hideEdit && (
          <div>
            <Tooltip title="Edit this row">
              <IconButton
                onClick={handleEdit}
                disabled={index === null || index === undefined || index < 0}
                className="h-fit"
                color="primary"
                type="button"
                aria-label="Edit row"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}

        {!hideSwaps && arrayLength > 1 && (
          <div className="flex items-center">
            <div>
              <Tooltip title="Move this row to one step above">
                <IconButton
                  onClick={handleSwapUpClick}
                  disabled={index === 0}
                  className="h-fit"
                  color="secondary"
                  type="button"
                  aria-label="Swap Row Up"
                >
                  <ArrowUpwardIcon />
                </IconButton>
              </Tooltip>
            </div>

            <div>
              <Tooltip title="Move this row to one step below">
                <IconButton
                  onClick={handleSwapDownClick}
                  disabled={index === (arrayLength ?? -1) - 1}
                  className="h-fit"
                  color="secondary"
                  type="button"
                  aria-label="Swap Row Down"
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}

        {!hideInsert &&
          index !== null &&
          index !== undefined &&
          index >= 0 &&
          index < arrayLength - 1 && (
            <div>
              <Tooltip title="Add a new row next to this row">
                <IconButton
                  onClick={handleInsertBelowThisRowClick}
                  disabled={index === null || index === undefined || index < 0}
                  className="h-fit"
                  color="success"
                  type="button"
                  aria-label="Insert Row below"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
      </div>
    </FormFieldWrapper>
  );
};

export default Actions;
