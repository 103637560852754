/* *created by Dipta Sikder*
|--------------------------------------------------
| About and Use of DynamicModal.jsx:
| - Dynamic Modal is the component that renders the modal dynamically.
| - It uses the ModalRegistry object to render the specific modal.
| - If the modal name is not found in the registry, it will return null.
| - This component should be imported and used in the root of the application but as a child of ModalContext.
|
| 
| Caution:
| - Don't delete this file.
| - Don't delete the comments.
| - Don't delete the default export.
| - This file is required for the system to work.
| - Please don't modify the code unless you know what you are doing.
|
|
| Tips:
| - Give my repos a star if you find it helpful.
| - Follow me on github for more useful repos.
| - My github profile: https://github.com/dipta-sikder-775
| - Let's connected & Endorse me for React and related skills on LinkedIn: https://www.linkedin.com/in/dipta-sikder-775/
|--------------------------------------------------
*/

import { memo } from "react";
import ModalRegistry from "../modal-registry";
import { useModal } from "./ModalContext";

const DynamicModal = () => {
  const modalProps = useModal();
  const { isOpen, modalName } = modalProps;

  if (
    !isOpen ||
    !modalName ||
    typeof modalName !== "string" ||
    !(modalName in ModalRegistry)
  )
    return null;

  const SpecificModal = ModalRegistry?.[modalName];
  if (!SpecificModal || typeof SpecificModal !== "function") return null;

  return <SpecificModal {...modalProps} />;
};

export default memo(DynamicModal);
