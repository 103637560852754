import { memo } from "react";
import { IoMdAdd } from "react-icons/io";
import CustomInput from "../../customInput";

const SearchOrAdd = ({
  search = {
    debounce: () => {},
    nonDebouncedValue: "",
  },
  enableSearch,

  addButton = {
    onAddNewOptionClick: (e) => {},
  },
  enableAddNewOption,
}) => {
  return (
    <>
      {(!!enableSearch || !!enableAddNewOption) && (
        <div className="!transition-linear sticky left-0 top-0 z-10 mb-2 flex items-center gap-2 bg-white !px-2 py-2 shadow-lg">
          {!!enableSearch && (
            <div
              className="w-full flex-1 border-b border-solid"
              onPointerDown={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <CustomInput
                type="search"
                value={search?.nonDebouncedValue}
                className="min-h-10 w-full"
                onChange={search?.debounce}
                placeholder="Search"
              />
            </div>
          )}

          {!!enableAddNewOption && (
            <button
              className="flex items-center justify-center rounded-lg border border-solid border-gray-300 bg-gray-100 p-3 text-gray-500 hover:bg-gray-200"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                addButton?.onAddNewOptionClick(e);
              }}
              onPointerDown={(e) => e.stopPropagation()}
            >
              <IoMdAdd className="h-5 w-5" />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default memo(SearchOrAdd);
