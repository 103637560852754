import Button from "@mui/material/Button";
import { createPromiseToast } from "../../../core/utils/promiseToast";
import { useModal } from "../../../modal-by-dipta-sikder/core/ModalContext";
import { useCreateAffiliateMarketplaceMutation } from "../../../redux/api/affiliateMarketplaceApi";
import CustomInput from "../../input/customInput";
import useAddAffiliateMarketplaceForm from "./useAddAffiliateMarketplaceForm";

const AddAffiliateProductForm = () => {
  const { data, closeModal } = useModal();
  const [createAffiliateMarketplace] = useCreateAffiliateMarketplaceMutation();
  // const [updateSmallAIFeature] = useUpdateSmallAIFeatureMutation();
  const { addAffiliateMarketplaceFormHookProps, initialValue } =
    useAddAffiliateMarketplaceForm();

  const { handleSubmit, reset, register, watch, formState } =
    addAffiliateMarketplaceFormHookProps;
  const { errors } = formState;
  console.log("errors: ", errors);

  const currentValues = watch();
  console.log("watch: ", currentValues);

  const onSubmit = async (values) => {
    const toast = createPromiseToast();
    const { errorToast, loadingToast, successToast } = toast();
    try {
      loadingToast({ message: "Adding affiliate marketplace..." });
      // console.log("form submit values: ", transformedValues);
      const res = await createAffiliateMarketplace(values).unwrap();
      successToast({ message: "Affiliate marketplace added successfully" });
      // console.log("res: ", res);
      reset(initialValue);
      closeModal();
    } catch (err) {
      console.log("err: ", err);
      errorToast({
        message: err?.data?.error_message || "Failed to add affiliate marketplace",
      });
    }
  };

  const content = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4">
        <CustomInput
          label={"Title"}
          type={"text"}
          placeholder={"Enter title"}
          {...register("title")}
          errorMessage={errors?.title?.message}
        />
      </div>

      <div className="my-5">
        <Button variant="contained" fullWidth type="submit">
          Create
        </Button>
      </div>
    </form>
  );

  return (
    <div className="!px-4 !pt-4 sm:!px-6">
      {/* <RenderData
        // data={getSmallAIFeatureByIdData}
        // apiState={getSmallAIFeatureByIdApiState}
        noContentFound={{
          message:
            "No previously saved marketplace found. You may create one or many.",
          afterContent: <div className="mt-5">{content}</div>,
        }}
      > */}
      {content}
      {/* </RenderData> */}
    </div>
  );
};

export default AddAffiliateProductForm;
