import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  epcList: [],
  actionList: [],
  affiliateRef: null,
};

export const affiliateSlice = createSlice({
  name: "affiliate",
  initialState,
  reducers: {
    addEpcList: (state, { payload }) => {
      state.epcList.push(payload);
    },
    addActionList: (state, { payload }) => {
      console.log(state.payload);
      state.actionList = [...state.actionList, payload];
    },
    addEpcListBulk: (state, { payload }) => {
      state.epcList = payload;
    },
    addActionListBulk: (state, { payload }) => {
      state.actionList = payload;
    },
    deleteAEpc: (state, { payload }) => {
      state.epcList = state.epcList
        ? state.epcList.filter((epc) => epc.id !== payload)
        : [];
    },
    deleteAActionType: (state, { payload }) => {
      state.actionList = state.actionList
        ? state.actionList.filter((action) => action.id !== payload)
        : [];
    },
    clearEpcList: (state) => {
      state.epcList = initialState.epcList;
    },
    clearActionList: (state) => {
      state.actionList = initialState.actionList;
    },
    clearEpcAndAction: (state) => {
      state.actionList = initialState.actionList;
      state.epcList = initialState.epcList;
    },
    setAffiliateRef: (state, { payload }) => {
      state.affiliateRef = payload;
    },
  },
});

export const {
  addEpcList,
  addActionList,
  clearEpcList,
  clearActionList,
  clearEpcAndAction,
  deleteAEpc,
  deleteAActionType,
  addActionListBulk,
  addEpcListBulk,
  setAffiliateRef,
} = affiliateSlice.actions;

export default affiliateSlice.reducer;
