import FormFieldWrapperCompound from "./FormFieldWrapperCompound";

const FormFieldWrapper = ({
  children,
  label,
  htmlFor,
  errorMessage,
  hintsMessage,
  classNames = {
    labelClassName: "",
    hintsClassName: "",
    errorMessageClassName: "",
  },
  ...restProps
}) => {
  return (
    <FormFieldWrapperCompound {...restProps}>
      {!!label && (
        <FormFieldWrapperCompound.Label
          label={label}
          htmlFor={htmlFor}
          className={classNames?.labelClassName}
        />
      )}

      {!!hintsMessage && (
        <FormFieldWrapperCompound.Hints
          hintsMessage={hintsMessage}
          className={classNames?.hintsClassName}
        />
      )}

      {children}

      {!!errorMessage && (
        <FormFieldWrapperCompound.ErrorMessage
          errorMessage={errorMessage}
          className={classNames?.errorMessageClassName}
        />
      )}
    </FormFieldWrapperCompound>
  );
};

export default FormFieldWrapper;
