import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { nanoid } from "@reduxjs/toolkit";
import { forwardRef, useMemo } from "react";
import { Controller } from "react-hook-form";
import { MdCheckCircle } from "react-icons/md";
import { cn } from "../../core/utils/cn";
import FormFieldWrapper from "./common-components/FormFieldWrapper";
import OptionsLoading from "./common-components/OptionsLoading";
import SearchOrAdd from "./common-components/SearchOrAdd";
import NoSearchResult from "./common-components/SearchOrAdd/NoSearchResult";
import useDebouncedSearch from "./common-components/SearchOrAdd/useDebouncedSearch";

const CustomSelect = forwardRef(
  (
    {
      name,
      control,
      defaultValue,
      options,
      value,
      key,
      className = "",
      classNames = {
        labelClassName: "",
        errorMessageClassName: "",
        wrapperClassName: "",
      },
      id = "value",
      val = "label",
      placeholder = "",
      label,
      errorMessage,
      hintsMessage,
      rules,
      disabled,
      readOnly,
      shouldUnregister,
      enableSearch,
      enableAddNewOption,
      onAddNewOptionClick = () => {},
      isLoading,
    },
    ref,
  ) => {
    const htmlForId = useMemo(() => {
      return name ? name?.trim() : nanoid();
    }, [name]);

    const {
      search: { debounce, nonDebouncedValue },
      searchFilteredOptions,
      isNoResultFound,
    } = useDebouncedSearch({
      fields: [val, id],
      options,
    });

    // console.log(
    //   "useDebouncedSearch:  form component: ",
    //   searchFilteredOptions,
    //   searchFilteredOptions?.length,
    // );

    const inputComponent = (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        disabled={disabled}
        readOnly={readOnly}
        shouldUnregister={shouldUnregister}
        render={({ field: { onChange, ...restFields } }) => {
          return (
            <Select
              id={htmlForId}
              onChange={onChange}
              {...restFields}
              value={value ? value : restFields?.value}
              ref={ref ? ref : restFields?.ref}
              displayEmpty
              key={key}
              inputProps={{ "aria-label": "Without label" }}
              className={cn(
                `flex min-h-14 w-full rounded-lg border border-gray-300 bg-transparent !outline-none ring-0 hover:border-brand-main-400 focus:border-brand-main-400 disabled:bg-gray-100`,
                {
                  "!border-gray-300 !bg-gray-100 !outline-none !ring-0":
                    disabled,
                },
                {
                  "!border-gray-300 !bg-transparent !outline-none !ring-0":
                    readOnly,
                },
                className,
              )}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.0)",
                  border: "none",
                  outline: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.0)",
                  border: "none",
                  outline: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.0)",
                  border: "none",
                  outline: "none",
                },
                "&.MuiInputBase-root .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  textWrap: "wrap",
                  wordBreak: "break-word",
                  position: "relative",
                  border: "none",
                  outline: "none",
                },
              }}
              renderValue={(selected) => {
                const selectedOption = options?.find(
                  (n) => n?.[id] === selected,
                );
                if (
                  selected === "none" ||
                  selected === "" ||
                  selected === null ||
                  selected === undefined ||
                  selectedOption?.[val] === null ||
                  selectedOption?.[val] === undefined
                ) {
                  return (
                    <span className="text-sm text-[#9CA3AF]/ !text-black">
                      {placeholder}
                    </span>
                  );
                }
                return selectedOption?.[val];
              }}
              readOnly={readOnly}
            >
              {!!placeholder && (
                <MenuItem disabled value="none">
                  {placeholder}
                </MenuItem>
              )}

              <SearchOrAdd
                enableSearch={enableSearch}
                search={{
                  debounce,
                  nonDebouncedValue,
                }}
                enableAddNewOption={enableAddNewOption}
                addButton={{
                  onAddNewOptionClick,
                }}
              />

              {isLoading && (
                <OptionsLoading options={options} isLoading={isLoading} />
              )}

              {searchFilteredOptions?.map((n) => (
                <MenuItem key={n?.[id]} value={n?.[id]}>
                  <span className="flex items-center gap-2">
                    <span
                      className={cn(
                        n?.[id] === restFields?.value
                          ? ""
                          : "!h-4 !w-4 flex-shrink-0",
                      )}
                    >
                      {n?.[id] === restFields?.value && (
                        <MdCheckCircle className="!h-4 !w-4 text-green-500" />
                      )}
                    </span>

                    <span className="flex flex-wrap items-center gap-2 text-wrap">
                      {!!n?.src && (
                        <img
                          src={n?.src}
                          alt={n?.[value]}
                          className="my-2 mr-4 w-8 border"
                        />
                      )}
                      <ListItemText
                        primary={n?.[val]}
                        className={"text-wrap break-words"}
                      />
                    </span>
                  </span>
                </MenuItem>
              ))}

              <NoSearchResult isNoResultFound={isNoResultFound} />
            </Select>
          );
        }}
      />
    );

    let content = inputComponent;
    if (!!errorMessage || !!label || !!hintsMessage) {
      content = (
        <FormFieldWrapper
          label={label}
          errorMessage={errorMessage}
          hintsMessage={hintsMessage}
          htmlFor={htmlForId}
          classNames={{
            labelClassName: classNames?.labelClassName,
            errorMessageClassName: classNames?.errorMessageClassName,
          }}
        >
          {inputComponent}
        </FormFieldWrapper>
      );
    }

    return content;
  },
);

export default CustomSelect;
