import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taskUserDetails: {},
  taskList: {},
  page: 1,
  outline: {},
  lastData: {},
  creditHistory: {},
  allFeatures: [],
  currentFeature: {},
  selectedActiveTab: 0,
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setTaskDetails: (state, { payload }) => {
      state.taskUserDetails = payload;
    },
    setTaskList: (state, { payload }) => {
      state.taskList = payload;
    },

    setPage: (state, { payload }) => {
      state.page = payload;
    },

    setOutline: (state, { payload }) => {
      state.outline = payload;
    },

    setLastData: (state, { payload }) => {
      state.lastData = payload;
    },
    setCreditHistory: (state, { payload }) => {
      state.creditHistory = payload;
    },
    getAllFeatures: (state, { payload }) => {
      state.allFeatures = payload;
    },
    setCurrentFeatures: (state, { payload }) => {
      console.log(payload);
      state.currentFeature = { payload };
    },
    setSelectedActiveTab: (state, { payload }) => {
      state.selectedActiveTab = payload;
    },
    taskLogoutAction: (state) => initialState,
  },
});

export const {
  setTaskDetails,
  setTaskList,
  taskLogoutAction,
  setPage,
  setOutline,
  setLastData,
  setCreditHistory,
  getAllFeatures,
  setCurrentFeatures,
  setSelectedActiveTab,
} = taskSlice.actions;

export default taskSlice.reducer;
