import { useMemo, useState } from "react";
import useDebounce from "../../../../hooks/useDebounce";

const useDebouncedSearch = ({
  fields = [],
  options = [],
  debouncedFunction = (props) => {},
  nonDebouncedFunction = (props) => {},
  valueSelector = (props) => props?.[0]?.target?.value,
  delay = 800,
}) => {
  const [search, setSearch] = useState("");
  const { debounce, nonDebouncedValue } = useDebounce({
    debouncedFunction: (props) => {
      setSearch(props);
      debouncedFunction?.(props);
    },
    nonDebouncedFunction,
    valueSelector,
    delay,
    value: "",
  });

  const searchFilteredOptions = useMemo(() => {
    if (!options?.length) return [];
    if (!search || !fields?.length) return options;
    const lowerCaseSearch = search.toLowerCase();

    return (
      options?.filter((option) => {
        return (
          fields?.some((field) => {
            const isFieldNameValid = field !== null && field !== undefined;
            const isFieldValueValid =
              isFieldNameValid &&
              option[field] !== null &&
              option[field] !== undefined;

            const fieldValue = String(option?.[field])?.toLowerCase();
            return isFieldValueValid && fieldValue.includes(lowerCaseSearch);
          }) ?? false
        );
      }) ?? []
    );
  }, [fields, options, search]);

  // console.log("useDebouncedSearch: ", {
  //   search,
  //   options,
  //   searchFilteredOptions,
  //   optionsLength: options?.length,
  //   searchFilteredOptionsLength: searchFilteredOptions?.length,
  // });

  return {
    search: {
      debouncedSearchValue: search,
      debounce,
      nonDebouncedValue,
    },
    searchFilteredOptions,
    isNoResultFound:
      !!search && !!options?.length && !searchFilteredOptions?.length,
  };
};

export default useDebouncedSearch;
