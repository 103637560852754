import * as Yup from "yup";

export const addOrEditActionTypeFormSchema = Yup.object().shape({
  type: Yup.string().required("Action type is required"),
  amount_type: Yup.string().required("Commission type is required"),
  amount: Yup.number()
    .positive("Commission amount must be a positive number")
    .nullable()
    .required("Commission amount is required"),
  currency: Yup.string().when("type", {
    is: (val) => val === "FIXED",
    then: (schema) => schema.required("Commission currency is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
